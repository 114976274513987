import { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import Map3D from './Map3D'

function MapHeader() {
  const containerRef = useRef<HTMLDivElement>(null)
  const { scrollY } = useScroll()
  
  const contentY = useTransform(scrollY, [0, 1000], ['0%', '100%'])
  const opacity = useTransform(scrollY, [0, 400], [1, 0])

  return (
    <div ref={containerRef} className="relative h-[75vh] overflow-hidden bg-[#6B46C1]">
      {/* 3D Map Background */}
      <div className="absolute inset-0 z-0">
        <Map3D />
      </div>

      {/* Purple Overlay */}
      <div className="absolute inset-0 z-10 bg-[#6B46C1] opacity-50" />

      {/* Content */}
      <div className="relative z-20">
        <motion.div 
          className="mx-auto max-w-7xl px-6 pt-32 sm:pt-40 lg:pt-44"
          style={{ opacity, y: contentY }}
        >
          <div className="mx-auto max-w-4xl text-center">
            <motion.h1 
              className="font-display text-4xl font-medium tracking-tight text-white sm:text-6xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Custom Map & Big Data Solutions
            </motion.h1>
            <motion.p 
              className="mt-6 text-lg leading-8 text-white/90"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              Specializing in interactive map-based web applications and large-scale data visualization
            </motion.p>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default MapHeader

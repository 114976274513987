export interface Project {
  title: string;
  description: string;
  image: string;
  technologies: string[];
  demoUrl?: string;
}

export const projects: Project[] = [
  {
    title: 'Dwellcome',
    description: 'A custom onboarding platform connecting newcomers with local amenities through interactive maps and personalized recommendations.',
    image: '/images/dwellcome.png',
    technologies: ['React', 'Node.js', 'MongoDB', 'MapBox', 'AWS'],
    
  },
  {
    title: 'Discover Erin',
    description: 'Location-based exploration platform showcasing local attractions and businesses with custom mapping solutions and real-time updates.',
    image: '/images/discover-erin.png',
    technologies: ['React', 'TypeScript', 'GIS', 'Firebase'],
    demoUrl: 'https://discovererin.ca'
  },
  {
    title: 'AltGrocery',
    description: 'Innovative grocery mapping solution helping users discover alternative food sources with custom map overlays and data visualization.',
    image: '/images/altgrocery.png',
    technologies: ['React', 'Node.js', 'PostgreSQL', 'D3.js'],
    demoUrl: 'https://altgrocery.io'
  },
];

import Header from './components/Navigation'
import MapHeader from './components/MapHeader'
import ProjectCard from './components/ProjectCard'
import About from './components/About'
import { motion } from 'framer-motion'
import { projects } from './data/projects'

function App() {
  return (
    <div className="relative bg-white">
      <Header />
      <main className="relative">
        {/* Hero Section */}
       <MapHeader />

        {/* About Section */}
        <About />

        {/* Projects Section */}
        <section
          id="projects"
          className="relative bg-white px-6 py-32 sm:py-40 lg:px-8"
        >
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="font-display text-3xl tracking-tight text-gray-900 sm:text-4xl">
              Featured Projects
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-600">
              Explore some of our recent work in custom mapping solutions and data visualization
            </p>
          </div>

          <div className="mx-auto mt-16 grid max-w-7xl grid-cols-1 gap-x-8 gap-y-16 sm:mt-20 lg:grid-cols-3 lg:gap-x-12">
            {projects.map((project, index) => (
              <ProjectCard
                key={project.title}
                {...project}
                delay={index * 0.2}
              />
            ))}
          </div>
        </section>

        {/* Contact Section */}
        <section
          id="contact"
          className="relative bg-[#6B46C1] px-6 py-32 sm:py-40 lg:px-8"
        >
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
              Let's Work Together
            </h2>
            <p className="mt-4 text-lg leading-8 text-white/90">
              Ready to bring your map-based project to life? Get in touch and let's discuss your ideas.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <motion.a
                href="mailto:contact@pixelmixel.ca"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="rounded-xl bg-white px-8 py-4 text-base font-semibold text-[#6B46C1] shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white transition-colors duration-200"
              >
                Get in Touch
              </motion.a>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default App

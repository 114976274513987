import React, { useEffect, useRef } from 'react';
import * as maptilersdk from '@maptiler/sdk';

interface Map3DProps {
  className?: string;
}

const Map3D: React.FC<Map3DProps> = ({ className }) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<maptilersdk.Map | null>(null);
  const animationFrame = useRef<number>();

  useEffect(() => {
    if (!mapContainer.current) return;

    const apiKey = 'ALmnkryIkDMG8zFXYIkL';
    maptilersdk.config.apiKey = apiKey;

    try {
      const initializedMap = new maptilersdk.Map({
        container: mapContainer.current,
        style: `https://api.maptiler.com/maps/streets/style.json?key=${apiKey}`,
        center: [-79.3795, 43.6505],
        zoom: 15.8,
        pitch: 65,
        bearing: -30,
        antialias: true,
        attributionControl: false,
        maxPitch: 85,
        minZoom: 15.8,
        maxZoom: 15.8,
        dragPan: false,
        dragRotate: false,
        scrollZoom: false,
        touchZoomRotate: false,
        doubleClickZoom: false,
        keyboard: false,
      });

      // Remove all controls
      const controls = initializedMap.getContainer().getElementsByClassName('maplibregl-ctrl');
      while (controls.length > 0) {
        controls[0].remove();
      }

      map.current = initializedMap;

      // Start rotation after map is fully loaded
      let bearing = -30;
      const rotateCamera = () => {
        if (map.current) {
          bearing -= 0.05;
          map.current.setBearing(bearing);
          animationFrame.current = requestAnimationFrame(rotateCamera);
        }
      };

      initializedMap.on('load', () => {
        rotateCamera();
      });

      // Handle errors
      initializedMap.on('error', (e) => {
        console.warn('Map error:', e.error);
      });

    } catch (error) {
      console.warn('Map initialization error:', error);
    }

    // Cleanup map and animation on unmount
    return () => {
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
      if (map.current) {
        map.current.remove();
      }
    };
  }, []);

  return (
    <div 
      ref={mapContainer} 
      className={`w-full h-full ${className || ''}`}
      style={{ background: '#6B46C1' }}
      role="region"
      aria-label="Interactive 3D map of Toronto"
    />
  );
};

export default Map3D;

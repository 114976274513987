import { motion } from 'framer-motion';

function About() {
  return (
    <section
      id="about"
      className="relative bg-[#6B46C1]/5 px-6 py-24 sm:py-32 lg:px-8"
    >
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <h2 className="font-display text-3xl tracking-tight text-gray-900 sm:text-4xl">
            Who we are
          </h2>
          <p className="mt-4 text-lg leading-8 text-gray-600">
            At Pixel Mixel, we believe in the power of collaboration, creativity, and smart work
            to create impactful designs that resonate with your audience and amplify your
            brand's message.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2"
        >
          <div className="relative rounded-2xl bg-white p-8 shadow-lg">
            <div className="absolute -top-4 -left-4 rounded-full bg-[#6B46C1] p-3">
              <span className="flex h-6 w-6 items-center justify-center text-lg font-semibold text-white">1</span>
            </div>
            <h3 className="text-xl font-semibold text-[#6B46C1]">
              Ideation
            </h3>
            <p className="mt-4 text-gray-600">
              We outline a tailored plan, select the right technology, and set realistic deadlines and costs to bring your idea to life.
            </p>
          </div>

          <div className="relative rounded-2xl bg-white p-8 shadow-lg">
            <div className="absolute -top-4 -left-4 rounded-full bg-[#6B46C1] p-3">
              <span className="flex h-6 w-6 items-center justify-center text-lg font-semibold text-white">2</span>
            </div>
            <h3 className="text-xl font-semibold text-[#6B46C1]">
              Mockup
            </h3>
            <p className="mt-4 text-gray-600">
              Whether it's wireframing your website or sketching initial designs, we focus on early conceptualization to shape your project's direction.
            </p>
          </div>

          <div className="relative rounded-2xl bg-white p-8 shadow-lg">
            <div className="absolute -top-4 -left-4 rounded-full bg-[#6B46C1] p-3">
              <span className="flex h-6 w-6 items-center justify-center text-lg font-semibold text-white">3</span>
            </div>
            <h3 className="text-xl font-semibold text-[#6B46C1]">
              Production
            </h3>
            <p className="mt-4 text-gray-600">
              Post-approval, we create high-quality assets. From selecting the imagery, colors, and fonts to refining the copy, every detail is meticulously crafted.
            </p>
          </div>

          <div className="relative rounded-2xl bg-white p-8 shadow-lg">
            <div className="absolute -top-4 -left-4 rounded-full bg-[#6B46C1] p-3">
              <span className="flex h-6 w-6 items-center justify-center text-lg font-semibold text-white">4</span>
            </div>
            <h3 className="text-xl font-semibold text-[#6B46C1]">
              Quality Assurance
            </h3>
            <p className="mt-4 text-gray-600">
              We rigorously test all links, review wording, and eliminate bugs to ensure a flawless launch and a smooth handover to you, our client.
            </p>
          </div>

          <div className="relative rounded-2xl bg-white p-8 shadow-lg sm:col-span-2">
            <div className="absolute -top-4 -left-4 rounded-full bg-[#6B46C1] p-3">
              <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-[#6B46C1]">
              Our Tech Stack
            </h3>
            <div className="mt-6 grid grid-cols-2 gap-4 sm:grid-cols-4">
              <div className="rounded-lg bg-[#6B46C1]/5 p-4 text-center">
                <p className="font-medium text-[#6B46C1]">React</p>
                <p className="mt-1 text-sm text-gray-600">Frontend Development</p>
              </div>
              <div className="rounded-lg bg-[#6B46C1]/5 p-4 text-center">
                <p className="font-medium text-[#6B46C1]">TypeScript</p>
                <p className="mt-1 text-sm text-gray-600">Type Safety</p>
              </div>
              <div className="rounded-lg bg-[#6B46C1]/5 p-4 text-center">
                <p className="font-medium text-[#6B46C1]">Tailwind</p>
                <p className="mt-1 text-sm text-gray-600">Styling</p>
              </div>
              <div className="rounded-lg bg-[#6B46C1]/5 p-4 text-center">
                <p className="font-medium text-[#6B46C1]">Node.js</p>
                <p className="mt-1 text-sm text-gray-600">Backend Solutions</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}

export default About;

import { motion } from 'framer-motion';
import { useState } from 'react';
import { Project } from '../data/projects';

interface ProjectCardProps extends Project {
  delay?: number;
}

function ProjectCard({ title, description, image, technologies, demoUrl, delay = 0 }: ProjectCardProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: '-50px' }}
      transition={{ duration: 0.6, delay }}
      className="group relative overflow-hidden rounded-2xl bg-white shadow-lg transition-shadow hover:shadow-xl"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      {/* Image Container */}
      <div className="relative h-64 overflow-hidden bg-[#6B46C1]/5">
        <div className="absolute inset-0 bg-[#6B46C1]/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
        <motion.div
          className="absolute inset-0"
          initial={false}
          animate={{
            scale: isHovered ? 1.05 : 1
          }}
          transition={{ duration: 0.6 }}
        >
          <img
            src={image}
            alt={`${title} project preview`}
            className="h-full w-full object-cover"
          />
        </motion.div>
      </div>

      {/* Content */}
      <div className="p-8">
        <motion.h3 
          className="font-display text-2xl font-semibold text-gray-900 group-hover:text-[#6B46C1] transition-colors duration-300"
          initial={false}
          animate={{
            y: isHovered ? -5 : 0
          }}
          transition={{ duration: 0.3 }}
        >
          {title}
        </motion.h3>
        <motion.p 
          className="mt-4 text-base leading-7 text-gray-600"
          initial={false}
          animate={{
            y: isHovered ? -3 : 0
          }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          {description}
        </motion.p>

        {/* Technologies */}
        <motion.div 
          className="mt-6 flex flex-wrap gap-2"
          initial={false}
          animate={{
            y: isHovered ? -2 : 0
          }}
          transition={{ duration: 0.3, delay: 0.15 }}
        >
          {technologies.map((tech) => (
            <span
              key={tech}
              className="inline-flex items-center rounded-full bg-[#6B46C1]/5 px-3 py-1 text-xs font-medium text-[#6B46C1]"
            >
              {tech}
            </span>
          ))}
        </motion.div>

        {/* Links */}
        {demoUrl && (
          <motion.div 
            className="mt-6 flex gap-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: isHovered ? 1 : 0,
              y: isHovered ? 0 : 20
            }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <a
              href={demoUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-sm font-medium text-[#6B46C1] transition-colors duration-200 hover:text-[#553399]"
            >
              Visit Website
              <svg 
                className="ml-2 h-4 w-4" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" 
                />
              </svg>
            </a>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
}

export default ProjectCard;
